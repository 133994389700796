const NATIVE = 'a';
const HLS = 'b';

let _hls = null;
let _audio = null;
let _preventPlay = false;
let _totalTime = null;
let _onPlayPause = null;
let _launcher = null;
let _streamApi;

function _getTimeHMS(seconds) {
    let hours = Math.floor(seconds / 3600);
    hours = hours > 0 ? hours + ':' : '';
    seconds %= 3600;
    let minutes = `00${Math.floor(seconds / 60)}:`.slice(-3);
    seconds = `00${Math.floor(seconds % 60)}`.slice(-2);
    return hours + minutes + seconds;
}

function _isNativeHLSSupported(audio) {
    if (audio.canPlayType('application/vnd.apple.mpegurl')) {
        if (navigator.userAgent.toLowerCase().match(/ios/)) return true;
        if (navigator.userAgent.toLowerCase().match(/mac/) && navigator.userAgent.toLowerCase().match(/safari/)) return true;
    }
    return false;
}

export const PLAYING = 1;
export const PAUSED = 0;

export function init(options) {

    _audio = options.audio;
    _onPlayPause = options.onPlayPause;

    if (_isNativeHLSSupported(_audio)) {
        _streamApi = NATIVE;
        _audio.addEventListener('loadedmetadata', function(event) {
            _totalTime = _audio.duration;
            options.onFileInfoLoaded();
        });
        _audio.addEventListener('canplay', function() {
            if (!_preventPlay) {
                play();
            } else {
                _preventPlay = false;
            }
        });
    } else if (Hls.isSupported()) {
        _streamApi = HLS;
        _hls = new Hls();
        let manifestListenerInstalled = false;
        let levelListenerInstalled = false;
        let updateTime;
        _hls.on(Hls.Events.MEDIA_ATTACHED, function() {
            if(!manifestListenerInstalled) {
                _hls.on(Hls.Events.MANIFEST_PARSED, function() {
                    updateTime = true;
                    if(!levelListenerInstalled) {
                        _hls.on(Hls.Events.LEVEL_LOADED, function(_event, data) {
                            if(updateTime) {
                                updateTime = false;
                                _totalTime = data.details.totalduration;
                                options.onFileInfoLoaded();
                            }   
                        });
                        levelListenerInstalled = true;
                    }
                });
                manifestListenerInstalled = true;
            }
        });

        _hls.attachMedia(_audio);
        _audio.addEventListener('canplaythrough', function() {
            if (!_preventPlay) {
                play();
            } else {
                _preventPlay = false;
            }
        });
    }

    _audio.addEventListener('timeupdate', function() {
        options.onProgress(_getTimeHMS(_audio.currentTime), _audio.currentTime, _totalTime);
    });

    _audio.addEventListener('ended', function() {
        pauseAndGoToStart();
    });

    _audio.addEventListener('progress', function(event) {
        if (_totalTime > 0) {
            let buffered = 0;
            if (_audio.buffered.length > 0) {
                buffered = _audio.buffered.end(_audio.buffered.length - 1) / _totalTime;
            }
            options.onBuffering(buffered);
        }
    });
}

export function getDurationHMS() {
    return _getTimeHMS(_totalTime);
}

export function getDuration() {
    return _totalTime;
}

export function getLauncher() {
    return _launcher
}

export function setCurrentTime(time) {
    _audio.currentTime = time;
}

export function pause() {
    _audio.pause();
    _onPlayPause(PAUSED);
}

export function play() {
    _audio.play();
    _onPlayPause(PLAYING);
}

export function pauseAndGoToStart() {
    pause();
    _preventPlay = true;
    _audio.currentTime = 0;
}

export function launch(button) {
    _launcher = button;
    let url = _launcher.getAttribute('data-file');
    if (!_audio.paused) pause();
    if (_streamApi === HLS) {
        _hls.loadSource(url);
    } else if (_streamApi === NATIVE) {
        _audio.src = url;
        _audio.load();
    }
    _audio.currentTime = 0;
} 
