/**
 * @callback MediaQueriesEventsCallback
 * @param {MediaQueryList[]} active
 */

/**
 * @type {MediaQueriesEventsCallback[]}
 */
let _callbacks = [];

/**
 * @type {Map<MediaQueryList, boolean>}
 */
let _mqls = new Map();
let _running = false;

/**
 * @param {MediaQueryList} mql 
 */
let _mqlChangeHandler = function (mql) {
    _mqls.set(mql, mql.matches);
    if(!_running) {
        _running = true;
        requestAnimationFrame(_runCallbacks);
    }
}

let _runCallbacks = function() {
    let active = Array.from(_mqls.keys).filter((mql) => _mqls.get(mql));
    _callbacks.forEach(function(callback) {
        callback(active);
    });
    _running = false;
}

export function buildList() {
    var sheets = document.styleSheets;
    for (var i = 0; i < sheets.length; i += 1) {
        var rules = sheets[i].cssRules;
        for (var j = 0; j < rules.length; j += 1) {
            if (rules[j].constructor === CSSMediaRule) {
                let mql = window.matchMedia(rules[j].media.mediaText);
                mql.onchange = _mqlChangeHandler;
                _mqls.set(mql, mql.matches);
            }
        }
    }
}

/**
 * @param {MediaQueriesEventsCallback} callback 
 */
export function addCallback (callback) {

    if (callback) {
        _callbacks.push(callback);
    }

}

/**
 * @param {MediaQueriesEventsCallback} callback 
 */
export function removeCallback (callback) {

    let index = _callbacks.indexOf(callback);
    if(index != -1) _callbacks.splice(index, 1);

}
