
export function init() {
    let timeoutId = null;
    let resizeEnd = new Event("ps-resize");
    window.addEventListener("resize", ()=> {
        if(timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            window.dispatchEvent(resizeEnd);
            timeoutId = null;
        }, 200);
    });
}
