/**
 * @enum {string}
 */
export const ScrollButtonState = {
    ACTIVATE : 'a',
    DEACTIVATE : 'b',
    UNCHANGED : 'c'
};
let _now = null;

const SCROLL = Symbol.for("SCROLL");

/**
 * @typedef ScrollOptions
 * @property {string} scrollWrapperClass
 * @property {string} scrollToClass
 * @property {Element} leftButton
 * @property {Element} rightButton
 * @property {ScrollWrapperCreatedCallback} onScrollWrapperCreated
 * @property {ScrollWrapperRemovedCallback} onScrollWrapperRemoved
 * @property {ScrollButtonUpdateCallback} onReachStart
 * @property {ScrollButtonUpdateCallback} onReachEnd
 * @property {ScrollButtonUpdateCallback} onLeaveStart
 * @property {ScrollButtonUpdateCallback} onLeaveEnd
 */

/**
 * @callback ScrollWrapperCreatedCallback
 * @param {Element} scrollable - the initial container
 * @param {Element} wrapper - the scroll wrapper to move the elements in 
 */

/**
 * @callback ScrollWrapperRemovedCallback
 * @param {Element} wrapper - the scroll wrapper to move the elements from 
 * @param {Element} scrollable - the receiver
 */

/**
 * @callback ScrollButtonUpdateCallback
 * @param {Element} scrollable - the element containing the buttons to update
 */

export default class Scroll {
    /**
     * 
     * @param {Element} scrollable 
     * @param {ScrollOptions} options 
     */
    constructor(scrollable, options) {
        if(!_now) {
            _now = window.performance && window.performance.now ?
                window.performance.now.bind(window.performance) :
                Date.now;
        }
        this.#scrollable = scrollable;
        this.#options = options;
        this.#wrapper = document.createElement('div');
        this.#wrapper.classList.add(this.#options.scrollWrapperClass);
        this.#scrollable.appendChild(this.#wrapper);
        this.#scroller = new Scroller(this.#wrapper);
        this.#options.onScrollWrapperCreated(this.#scrollable, this.#wrapper);
        this.#left = options.leftButton;
        this.#right = options.rightButton;
        this.reset();
        this.#wrapper.addEventListener("scroll", this.#onScroll);
        this.#left.addEventListener('click', this.#onClick, {capture : true, passive : false});
        this.#right.addEventListener('click', this.#onClick, {capture : true, passive : false});
        window.addEventListener('ps-resize', this.#onResize);
    }

    start() {
        this.#options.onScrollWrapperCreated(this.#scrollable, this.#wrapper);
        this.#scrollable.appendChild(this.#wrapper);
        this.#scrollable.appendChild(this.#left);
        this.#scrollable.appendChild(this.#right);
        this.#wrapper.addEventListener("scroll", this.#onScroll);
        this.#left.addEventListener('click', this.#onClick, {capture : true, passive : false});
        this.#right.addEventListener('click', this.#onClick, {capture : true, passive : false});
        window.addEventListener('ps-resize', this.#onResize);
    }

    stop() {
        this.#wrapper.removeEventListener('scroll', this.#onScroll);
        this.#left.removeEventListener('click', this.#onClick, {capture : true, passive : false});
        this.#right.removeEventListener('click', this.#onClick, {capture : true, passive : false});
        window.removeEventListener('ps-resize', this.#onResize);
        this.#left.remove();
        this.#right.remove();
        this.#wrapper.remove();
        this.#options.onScrollWrapperRemoved(this.#wrapper, this.#scrollable);
    }

    reset() {
        if(this.#scroller.isScrolling()) this.#scroller.stopScrolling();
        this.#pos = 0;
        this.#wrapper.scrollLeft = 0;
        this.#positions = this.#getScrollPositions();
        this.#options.onReachStart(this.#scrollable);
        if (this.#positions.length > 0) {
            this.#options.onLeaveEnd(this.#scrollable);
        } else {
            this.#options.onReachEnd(this.#scrollable);
        }
    }

    /**
     * @type {Element}
     */
    #scrollable = null;

    /**
     * @type {ScrollOptions}
     */
    #options = null;

    /**
     * @type {Element}
     */
    #wrapper;

    /**
     * @type {Element}
     */
    #left = null;

    /**
     * @type {Element}
     */
    #right = null;

    /**
     * @type {number[]}
     */
    #positions = null;

    /**
     * @type {number}
     */
    #pos = -1;

    /**
     * @type {Scroller}
     */
    #scroller = null;

    /**
     * @param {number} a
     * @param {number} b
     * @returns {number}  - midpoint
     */
    #mid(a, b) {
       return a < b ? a + (b - a) / 2.0 : a > b ? b + (a - b) / 2.0 : a;
    }

    /**
     * @type {EventListener}
     */
    #onClick = (event) => {
        event.preventDefault();
        let button = event.currentTarget;
        let scrollToPosition = null;
        if(button === this.#left) {
            if(this.#pos !== 0) scrollToPosition = this.#pos - 1;
        } else {
            if(this.#pos !== this.#positions.length - 1) scrollToPosition = this.#pos + 1;
        }
        if(scrollToPosition !== null) {
            this.#scroller.scrollTo(
                this.#positions[scrollToPosition],
                this.#wrapper.scrollTop,
                _now(),
                250
            );
        }
    };

    /**
     * @type {EventListener}
     */
    #onScroll = () => {
        if(
            this.#pos < this.#positions.length - 1 &&
            this.#wrapper.scrollLeft > this.#mid(this.#positions[this.#pos], this.#positions[this.#pos + 1])
        ) {
            if(this.#pos === 0) this.#options.onLeaveStart(this.#scrollable);
            this.#pos++;
            if(this.#pos === this.#positions.length - 1) this.#options.onReachEnd(this.#scrollable);
        } else if(
            this.#pos > 0 &&
            this.#wrapper.scrollLeft < this.#mid(this.#positions[this.#pos], this.#positions[this.#pos - 1])
        ) {
            if(this.#pos === this.#positions.length - 1) this.#options.onLeaveEnd(this.#scrollable);
            this.#pos--;
            if(this.#pos === 0) this.#options.onReachStart(this.#scrollable);
        }
    }

    /**
     * @type {EventListener}
     */
    #onResize = () => {
        let wasFirst = this.#pos === 0;
        let wasLast = this.#pos === this.#positions.length - 1;

        this.#positions = this.#getScrollPositions();
        this.#pos = this.#findCurrentPos();

        if(this.#pos > 0) {
            if(wasFirst) this.#options.onLeaveStart(this.#scrollable);
        } else {
            if(!wasFirst) this.#options.onReachStart(this.#scrollable);
        }
        if(this.#pos < this.#positions.length - 1) {
            if(wasLast) this.#options.onLeaveEnd(this.#scrollable);
        } else {
            if(!wasLast) this.#options.onReachEnd(this.#scrollable);
        }
    }

    #findCurrentPos() {
        let pos = 0;
        while(
            pos < this.#positions.length - 1 &&
            this.#wrapper.scrollLeft > this.#mid(this.#positions[pos], this.#positions[pos + 1])
        ) {
            pos++;
        }
        return pos;
    }

    /**
     * @param {Element} element 
     * @returns {number}
     */
    #rightEdge(element) {
        let right = element.nextElementSibling;
        if(right) return right.offsetLeft;
        else return element.parentElement.scrollWidth;
    }

    /**
     * @returns {number[]}
     */
    #getScrollPositions() {
        let scrollTos = this.#scrollable.getElementsByClassName(this.#options.scrollToClass);
        let firstOffScreenIndex = 0;
        let offScreen = scrollTos.item(firstOffScreenIndex);
        let rightEdge;
        while (offScreen && (rightEdge = this.#rightEdge(offScreen)) <= this.#wrapper.clientWidth) {
            firstOffScreenIndex++;
            offScreen = scrollTos.item(firstOffScreenIndex);
        }

        if(!offScreen) return [];
        
        let positions = new Array(scrollTos.length - firstOffScreenIndex + 1);
        positions[0] = 0.0;
        positions[1] = rightEdge - this.#wrapper.clientWidth;
        let pos = 2;
        while (pos < positions.length) {
            offScreen = scrollTos.item(firstOffScreenIndex + pos - 1);
            positions[pos] = this.#rightEdge(offScreen) - this.#wrapper.clientWidth;
            pos++;
        }
        return positions;
    }
}

class Scroller {
    /**
     * @param {Element} element - the element which will scroll
     */
    constructor(element) {
        this.#element = element;
    }

    /**
     * @param {number} x - scroll end pos x
     * @param {number} y - scroll end pos y
     * @param {number} start - the time at which we started scrolling
     * @param {number} duration - total duration of scrolling
     */
    scrollTo(x, y, start, duration) {
        this.#x = x;
        this.#y = y;
        this.#startX = this.#element.scrollLeft;
        this.#startY = this.#element.scrollTop;
        this.#start = start;
        this.#duration = duration;
        this.#frameRequestId = window.requestAnimationFrame(this.#scroll);
    }

    /**
     * @returns {bool}
     */
    isScrolling() {
        return this.#frameRequestId !== 0;
    }

    stopScrolling() {
        window.cancelAnimationFrame(this.#frameRequestId);
        this.#frameRequestId = 0;
    }

    /**
     * @type {FrameRequestCallback}
     */
    #scroll = () => {
        let time = _now();
        let value;
        let currentX;
        let currentY;
        let elapsed = (time - this.#start) / this.#duration;
    
        // avoid elapsed times higher than one
        elapsed = elapsed > 1 ? 1 : elapsed;
    
        // apply easing to elapsed time
        value = _ease(elapsed);
    
        currentX = this.#startX + (this.#x - this.#startX) * value;
        currentY = this.#startY + (this.#y - this.#startY) * value;
    
        this.#element.scrollLeft = currentX;
        this.#element.scrollTop = currentY;
    
        // scroll more if we have not reached our destination
        if (currentX !== this.#x || currentY !== this.#y) {
            this.#frameRequestId = window.requestAnimationFrame(this.#scroll);
        } else {
            this.#frameRequestId = 0;
        }
    }

    /**
     * @type {Element}
     */
    #element = null;

    /**
     * @type {number}
     */
    #x = -1;

    /**
     * @type {number}
     */
    #startX = -1;

    /**
     * @type {number}
     */
    #y = -1;

    /**
     * @type {number}
     */
    #startY = -1;

    /**
     * @type {number}
     */
    #start = 0;

    /**
     * @type {number}
     */
    #duration = -1;

    /**
     * @type {number}
     */
    #frameRequestId = 0;
}

function _ease(k) {
    return 0.5 * (1 - Math.cos(Math.PI * k));
}



