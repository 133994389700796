let _responseHandlers = null;
let _csrfTokenName = null;
let _contentTypeExtractor = /^.+\/([a-z]+)\s;/;

export function init(options) {
    _csrfTokenName = options.csrfTokenName;
    _responseHandlers = options.responseHandlers;

    let url = window.location.pathname === '/' ? window.location.pathname : window.location.pathname.substring(1);
    history.replaceState({ page : url }, '', url);

    window.addEventListener('popstate', (event) => {
        navigate(event.state.page, false);
    });

    processLinks();
}

function navigate(href, hist = true) {
    request({ href : href, method : 'get', body : undefined }, true, hist);
}

export function request(req, addLinksHandlers = false, addToHistory = false) {
    let headers = new Headers();
    headers.append('jsnav-fetch', 'true');
    if(_csrfTokenName) {
        let csrfToken = window.localStorage.getItem(_csrfTokenName);
        if(csrfToken) headers.append('csrf-token', csrfToken);
    }
    let options = { method : req.method ? req.method.toUpperCase() : 'GET', headers : headers, body : req.body ? req.body : undefined };
    fetch(req.href, options)
    .then(response => {
        let body;
        let contentType = response.headers.get('content-type');
        switch (_contentTypeExtractor.exec(contentType)[1]) {
            case 'json':
                body = response.json();
                break;
            default:
                body = response.text();
                break;
        }
        return body;
    })
    .then(body => {
        if(addToHistory) history.pushState({page : req.href}, '', req.href);
        let handler = _responseHandlers[req.href];
        if(handler) {
            let method = req.method ? req.method.toLowerCase() : 'get';
            if(handler.hasOwnProperty(method)) {
                handler[method](body);
            } else {
                handler(body);
            }
        } else {
            _responseHandlers['default'](body);
        }
        if(addLinksHandlers) processLinks();
    });
}

function clickHandler(event) {
    event.preventDefault();
    navigate(event.currentTarget.getAttribute('href'), true);
}

function processLinks() {
    for(let link of document.body.getElementsByClassName('JsNavLink')) {
        link.removeEventListener('click', clickHandler, {capture : true, passive : false});
        link.addEventListener('click', clickHandler, {capture : true, passive : false});
    }
}